<template>
  <v-select
    :loading="loadingExamPartership"
    :value="value"
    @change="$emit('input', $event)"
    :items="examPartershipOptions"
    label="Empresa/Órgão conveniado:"
  ></v-select>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      loadingExamPartership: false,
      examPartershipOptions: [],
    };
  },
  methods: {
    setResponseData(data) {
      this.examPartershipOptions = data.map((d) => {
        return {
          value: d.id,
          text:
            d.Person.type === "legal-person"
              ? d.Person.fantasyName
              : d.Person.fullName,
        };
      });

      this.loadingExamPartership = false;
    },
    async loadExamPartership() {
      try {
        this.loadingExamPartership = true;

        this.examPartershipOptions = [];

        const url = `/partnerships?pagination=0`;

        const res = await this.$axios.get(url);

        this.setResponseData(res.data);
      } catch (error) {
        this.loadingExamPartership = false;

        this.errorLoadingData(error);
      }
    },
    errorLoadingData(error) {
      // if (Array.isArray(error)) {
      //   error.forEach((e) => {
      //     this.$toasted.global.defaultError({ msg: `${e}` });
      //   });
      // } else {
      //   this.$toasted.global.defaultError({ msg: `${error}` });
      // }
      console.log(error);
    },
  },
  created() {
    this.loadExamPartership();
  },
};
</script>

<style></style>
