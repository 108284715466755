<template>
  <div>
    <v-combobox
      @input="onSelect"
      :value="value"
      :items="people"
      :loading="loading"
      :search-input.sync="search"
      item-text="fullName"
      item-value="id"
      return-object
      placeholder="Busque aqui"
      :label="label"
      class="required"
      :hide-details="hideDetails"
      cache-items
      :clearable="!disabled && !readonly"
      :disabled="disabled"
      :readonly="readonly"
      ref="peopleAutocomplete"
    >
      <template v-slot:append-item>
        <v-btn text small @click.stop="insertionDialog = true">
          Cadastrar
          <v-icon right color="blue">mdi-plus-circle-outline</v-icon></v-btn
        >
      </template>
    </v-combobox>

    <v-dialog v-model="insertionDialog">
      <PersonForm
        :mod="'insert'"
        :modalTitle="'Cadastrar paciente'"
        :typeOfLink="typeOfLink"
        dialog
        divider
        @close-dialog="insertionDialog = false"
        @insert-completed="setPersonAdded"
      />
    </v-dialog>
  </div>
</template>

<script>
import PersonForm from "@/views/People/PersonForm";
import errorHandler from "@/helpers/error_handler";

export default {
  components: { PersonForm },
  props: [
    "value",
    "typeOfLink",
    "disabled",
    "readonly",
    "label",
    "hide-details",
  ],
  data() {
    return {
      insertionDialog: false,
      loading: false,
      search: null,
      people: [],
    };
  },
  watch: {
    search(val) {
      if (this.loading || !val || val !== this.search) return;

      this.loadPeople(val);
    },
    typeOfLink() {
      this.clearAutocomplete();
    },
  },
  methods: {
    onSelect(evt) {
      if (evt) {
        this.$emit("input", evt);
        this.search = null;
      }
    },
    setPersonAdded(data) {
      const formattedData = {
        ...data,
      };

      this.people.push(formattedData);

      this.$emit("input", formattedData);

      this.insertionDialog = false;
    },
    setPeople(data) {
      const copyOfData = [...data];

      this.people = copyOfData;
    },
    async loadPeople(search) {
      try {
        this.loading = true;

        const params = {
          pagination: "false",
          search,
        };

        if (this.typeOfLink) params.typeOfLink = this.typeOfLink;

        const response = await this.$axios.get(`/people`, { params });

        this.setPeople(response.data);
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loading = false;
      }
    },
    clearAutocomplete() {
      this.search = null;
      this.$emit("input", null);
      this.$refs.peopleAutocomplete.cachedItems = [];
    },
  },
  computed: {},
};
</script>

<style></style>
