<template>
  <v-select
    :value="value"
    @change="$emit('input', $event)"
    :items="urgenceStatusOptions"
    label="Urgência:"
    hint="Selecione a urgência do exame"
  ></v-select>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      urgenceStatusOptions: [
        { text: "Sem urgência", value: "no-urgency" },
        { text: "Urgência intermediária", value: "intermediate-urgency" },
        { text: "Urgente", value: "urgent" },
      ],
    };
  },
};
</script>

<style></style>
