<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading || loadingExamData"
    @save="save"
  >
    <template #content>
      <v-container fluid>
        <v-row>
          <v-col>
            <PeopleAutocomplete
              label="Paciente: "
              v-model="form.Person"
              :typeOfLink="3"
            />
          </v-col>
        </v-row>

        <v-row v-if="type === 'private'">
          <v-col>
            <ExamModeSelect
              class="required"
              :fromExamForm="true"
              v-model="form.examMode"
            />
          </v-col>
        </v-row>

        <!-- no contexto do insert usa-se o type (props) para saber se o campo será oculto
        e no contexto do update, usa-se o próprio objeto form, pois o type será nulo -->
        <v-row v-if="form.type === 'public' || type === 'public'">
          <v-col>
            <ProcedureAutocomplete
              class="required"
              :type="'exam'"
              :multiple="false"
              v-model="form.Procedure"
            />
          </v-col>
        </v-row>

        <v-row v-if="form.type === 'public' || type === 'public'">
          <v-col>
            <ExamPartnershipsSelect v-model="partnershipId" class="required" />
          </v-col>
        </v-row>

        <v-row v-if="partnershipId">
          <v-col>
            <ExamBranchSelect
              :partnershipId="partnershipId"
              v-model="form.PartnershipBranchId"
              class="required"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Data do exame"
              v-model="form.examDate"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              type="time"
              v-model="form.examHour"
              label="Hora do exame:"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="form.clinicalData"
              label="Dados clínicos:"
              class="required"
              counter="254"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="form.requestedBy"
              label="Solicitado por:"
              placeholder="Nome do médico que solicitou o exame"
              class="required"
              counter="254"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="mod === 'update'">
          <v-col>
            <h4>Arquivos do exame:</h4>
          </v-col>
        </v-row>

        <div v-if="mod === 'update'">
          <v-row
            class="mt-2"
            v-bind:key="archive.id"
            v-for="archive in form.srcFiles"
          >
            <v-col>
              <v-chip @click="setImageShow(archive.id)">{{
                archive.name
              }}</v-chip>
            </v-col>
          </v-row>
        </div>

        <v-row v-if="form.srcFiles && form.srcFiles.length">
          <v-col>
            <v-btn
              class="ma-2"
              small
              rounded
              color="primary"
              @click="deleteArchives()"
            >
              <v-icon left dark> mdi-close </v-icon> Remover arquivos
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <ExamUrgence v-model="form.urgentExamStatus" class="required" />
          </v-col>
          <v-col cols="12" md="6">
            <v-file-input
              v-model="files"
              ref="files"
              show-size
              label="Adicionar arquivo (s):"
              :disabled="form.srcFiles && form.srcFiles.length > 0"
              multiple
              :hint="
                mod === 'update'
                  ? 'É necessário remover todos os arquivos e submeter novamente'
                  : ' '
              "
              :persistent-hint="mod === 'update'"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions></template>
  </BaseForm>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import errorHandler from "@/helpers/error_handler";
import { mask } from "vue-the-mask";
import { mapState, mapActions } from "vuex";

import BaseForm from "@/components/template/BaseForm";

import ExamPartnershipsSelect from "@/components/template/FormElements/Exams/ExamPartnershipsSelect";
import ExamBranchSelect from "@/components/template/FormElements/Exams/ExamBranchSelect";
import ExamUrgence from "@/components/template/FormElements/Exams/ExamUrgence";
import ExamModeSelect from "@/components/template/FormElements/Masks/ExamModeSelect";
import ProcedureAutocomplete from "@/components/template/FormElements/Procedures/ProceduresAutocomplete";
import PeopleAutocomplete from "@/components/template/FormElements/People/PeopleAutocomplete";

export default {
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  components: {
    BaseForm,
    ExamBranchSelect,
    ExamUrgence,
    ExamPartnershipsSelect,
    ExamModeSelect,
    ProcedureAutocomplete,
    PeopleAutocomplete,
  },
  mixins: [formMixin],
  directives: { mask },
  computed: {
    ...mapState("examForm", ["examFormData", "loadingExamData"]),
    examId() {
      return this.$route.params.id;
    },
    schedulingProcedureId() {
      return this.$route.params.schedulingProcedureId;
    },
  },
  data() {
    return {
      loading: false,
      loadingAutocomplete: false,
      insertionDialog: false,
      partnershipId: null,
      patient: {},
      form: {},
      files: [],
    };
  },
  methods: {
    ...mapActions("medicalReport", ["vGetExamFileUrl"]),
    ...mapActions("examForm", ["setExamFormData"]),
    save(actionAfterOperation) {
      const errors = this.validateForm();
      // const errors = [];

      console.log(this.form);

      if (errors.length) {
        this.$root.$errorDialog(errors, {
          width: "800",
          color: "primary",
        });
      } else {
        this[this.mod](actionAfterOperation);
      }
    },
    async insert(actionAfterOperation) {
      try {
        this.loading = true;

        let formData = new FormData();

        for (let i = 0; i < this.files.length; i++) {
          formData.append("file", this.files[i]);
        }

        let dataToSend = this.formatDataToSend(this.form);

        for (const key of Object.keys(dataToSend)) {
          formData.append(key, dataToSend[key]);
        }

        let url = `/exams`;

        const response = await this.$axios.post(url, formData);

        this.loading = false;

        this.files = [];

        this.afterInsertion(actionAfterOperation, {
          ...this.form,
          id: response.data.id,
        });
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async update(actionAfterUpdate) {
      try {
        this.loading = true;

        let formData = new FormData();

        for (let i = 0; i < this.files.length; i++) {
          formData.append("file", this.files[i]);
        }

        let dataToSend = this.formatDataToSend(this.form);

        for (const key of Object.keys(dataToSend)) {
          formData.append(key, dataToSend[key]);
        }

        const response = await this.$axios.put(
          `/exams/${this.examId}`,
          formData
        );

        this.addUploadedImages(response.data);

        this.files = [];

        this.afterUpdate(actionAfterUpdate);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadExamData() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/exams/${this.examId}`);

        this.setExamData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    addUploadedImages(examFiles) {
      const copyOfExamFiles = [...examFiles];

      //adiciona as imagens
      for (let i = 0; i < copyOfExamFiles.length; i++) {
        this.form.srcFiles.push({
          id: copyOfExamFiles[i].id,
          name: `${examFiles[i].fileName}`,
        });
      }
    },
    setExamData(data) {
      const copyOfData = { ...data, srcFiles: [] };

      let examFiles = data.ExamFiles;

      //adiciona as imagens
      for (let i = 0; i < examFiles.length; i++) {
        copyOfData.srcFiles.push({
          id: examFiles[i].id,
          name: `${examFiles[i].fileName}`,
        });
      }

      //fotmata data e hora
      [copyOfData.examDate, copyOfData.examHour] =
        copyOfData.dateOfRealization.split("T");

      copyOfData.examHour = copyOfData.examHour.split(".")[0];

      if (copyOfData.type === "public") {
        //adiciona campo do partnsership
        this.partnershipId = copyOfData.PartnershipBranch.PartnershipId;
        //adiciona o procedimento escolhido no autocomplete
        copyOfData.Procedure = {
          id: copyOfData.ExamReference.Procedure.id,
          name: copyOfData.ExamReference.Procedure.name,
          ExamReference: copyOfData.ExamReference,
        };
      }

      //adiciona objeto do paciente
      let auxPerson = copyOfData.Patient.Person;

      copyOfData.Person = {
        id: auxPerson.id,
        fullName:
          auxPerson.type === "legal-person"
            ? auxPerson.fantasyName
            : auxPerson.fullName,
        Patient: {
          PersonId: auxPerson.id,
          id: copyOfData.Patient.id,
        },
      };

      this.form = copyOfData;
    },
    formatDataToSend(data) {
      let dataCopy = { ...data };

      dataCopy.PatientId = dataCopy.Person.Patient.id;

      dataCopy.dateOfRealization = `${dataCopy.examDate} ${dataCopy.examHour}`;

      //Se for update não precisa editar este campo
      if (this.mod === "insert") dataCopy.type = this.type;

      if (dataCopy.type === "private" || this.type === "private")
        dataCopy.SchedulingProcedureId = this.schedulingProcedureId;

      if (dataCopy.type === "public" || this.type === "public") {
        dataCopy.examMode = dataCopy.Procedure.ExamReference.ExamMode.name;
        dataCopy.ExamReferenceId = dataCopy.Procedure.ExamReference.id;
        dataCopy.ProcedureId = dataCopy.Procedure.id;
      }

      return dataCopy;
    },
    validateForm() {
      const errors =
        this.type === "public"
          ? this.validatePublicExamForm()
          : this.validatePrivateExamForm();

      return errors;
    },
    validatePrivateExamForm() {
      const errors = [];

      if (!this.form.examDate || !this.form.examHour)
        errors.push("Data e hora do exame obrigatórios!");

      if (this.mod === "insert" && this.files.length == 0)
        errors.push("Informe pelo menos um arquivo!");

      if (!this.form.Person) errors.push("Infome o paciente!");

      if (!this.form.examMode) errors.push("Campo 'Modalidade' obrigatório!");

      if (!this.form.urgentExamStatus)
        errors.push("Defina uma urgência para o exame!");

      if (!this.form.clinicalData) errors.push("Dados clínicos obrigatórios!");

      if (!this.form.requestedBy)
        errors.push("Informe o médico que solicitou o exame!");

      if (
        this.mod === "update" &&
        !this.form.srcFiles.length &&
        this.files.length == 0
      )
        errors.push("Anexe os arquivos para o exame!");

      return errors;
    },
    validatePublicExamForm() {
      const errors = [];

      if (!this.form.examDate || !this.form.examHour)
        errors.push("Data e hora do exame obrigatórios!");

      if (this.mod === "insert" && this.files.length == 0)
        errors.push("Informe pelo menos um arquivo!");

      if (!this.form.Person) errors.push("Infome o paciente!");

      if (!this.form.clinicalData) errors.push("Dados clínicos obrigatórios!");

      if (!this.form.requestedBy)
        errors.push("Informe o médico que solicitou o exame!");

      if (!this.partnershipId)
        errors.push("Empresa/Órgão conveniado obrigatório!");

      if (!this.form.PartnershipBranchId) errors.push("Local obrigatório!");

      if (!this.form.urgentExamStatus)
        errors.push("Defina uma urgência para o exame!");

      if (
        this.mod === "update" &&
        !this.form.srcFiles.length &&
        this.files.length == 0
      )
        errors.push("Anexe os arquivos para o exame!");

      return errors;
    },
    async confirmDialog(message) {
      return await this.$root.$confirm("Atenção", message, {
        color: "primary",
      });
    },
    async deleteArchives() {
      try {
        if (
          !(await this.confirmDialog(
            "Deseja realmente deletar os arquivos do exame?"
          ))
        )
          return;

        this.loading = true;

        await this.$axios.delete(`/exams/${this.examId}/files`);

        this.form.srcFiles = [];

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async setImageShow(fileId) {
      try {
        this.loading = true;

        const examId = this.examId;

        const fileUrl = await this.vGetExamFileUrl({ examId, fileId });

        console.log(fileUrl);

        window.open(fileUrl, "_blank");
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    async initFormFromSchedule() {
      try {
        await this.setExamFormData(this.schedulingProcedureId);
        this.form = { ...this.examFormData };
      } catch (error) {
        this.handleError(error);
      }
    },
    async handleError(error) {
      console.log(error);
      const errorHandled = errorHandler.treatError(error);

      await this.$root.$errorDialog(errorHandled, {
        width: "800",
        color: "primary",
      });
    },
  },
  created() {
    if (this.mod === "update") this.loadExamData();
    if (this.type === "private" && this.mod === "insert") {
      this.initFormFromSchedule();
    }
  },
};
</script>

<style>
.label-mask {
  font-size: 1.05rem;
}
</style>
